<template>
  <div>
    <div class="alert alert-custom alert-default" role="alert">
      <div class="alert-icon">
        <i class="flaticon-close text-danger"></i>
      </div>
      <div class="alert-text">
        Đã có lỗi xảy ra trong quá trình khởi tạo xóa dữ liệu của
        <span class="font-italic font-weight-bold">Thế giới máy cũ</span>
        khỏi D-CORE. Vui lòng liên hệ Tech để được hotfix.
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Failed',
};
</script>
