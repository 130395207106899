<template>
  <div>
    <div class="form-group mb-8">
      <div class="alert alert-custom alert-default" role="alert">
        <div class="alert-icon">
          <i class="flaticon-warning text-warning"></i>
        </div>
        <div class="alert-text">
          Thao tác này sẽ
          <span class="text-danger font-weight-bold">XOÁ TOÀN BỘ</span>
          các thông tin của
          <span class="font-italic font-weight-bold">Thế giới máy cũ</span>
          khỏi D-CORE.
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="exampleInputPassword1" class="font-weight-bold"
        >Mật khẩu: <span class="text-danger">*</span></label
      >
      <input
        type="password"
        :class="`form-control mb-2 ${passwordError.hasError ? 'is-invalid' : ''}`"
        @input="updateValue"
        placeholder="Nhập mật khẩu của bạn"
      />
      <p v-if="passwordError.hasError" class="text-danger">
        {{ passwordError.message }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RecheckPassword',
  props: ['password', 'passwordError'],
  methods: {
    updateValue(event) {
      this.$emit('update:password', event.target.value);
    },
  },
};
</script>
