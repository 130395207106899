<template>
  <div>
    <div class="alert alert-custom alert-default" role="alert">
      <div class="alert-icon">
        <i class="flaticon2-checkmark text-success"></i>
      </div>
      <div class="alert-text">
        Đang xóa dữ liệu của
        <span class="font-italic font-weight-bold">Thế giới máy cũ</span>, bạn
        có thể tắt trình duyệt này. Khi hoàn thành, hệ thống sẽ gửi email báo cáo
        kết quả đến bạn. Thời gian cho thao tác này sẽ mất từ 30 - 60 phút
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Success',
};
</script>
