<template>
  <div>
    <div class="form-group mb-8">
      <div class="alert alert-custom alert-default" role="alert">
        <div class="alert-icon">
          <i class="flaticon-warning text-danger font-weight-boldest"></i>
        </div>
        <div class="alert-text">
          <div>
            Mã OTP đã được gửi đến số điện thoại của bạn.<br />Vui lòng kiểm tra
            tin nhắn điện thoại.
          </div>
          <br />
          <div>
            <span class="font-weight-boldest">Lưu ý:</span> Sau khi nhập OTP và
            xác nhận, bạn
            <span class="text-danger font-weight-boldest"
              >KHÔNG THỂ HOÀN TÁC</span
            >
            thao tác này
            <span class="text-danger font-weight-boldest">kể cả khi bạn tắt trình duyệt</span>
          </div>
        </div>
        <div class="alert-text"></div>
      </div>
    </div>

    <div class="form-group">
      <label for="exampleInputPassword1" class="font-weight-bold"
        >Mã OTP: <span class="text-danger">*</span></label
      >
      <input
        type="text"
        :class="`form-control mb-2 ${otpError.hasError ? 'is-invalid' : ''}`"
        @input="updateValue"
        placeholder="Nhập mã OTP của bạn"
      />
      <p v-if="otpError.hasError" class="text-danger">
        {{ otpError.message }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OTPGeneration',
  props: ['otp', 'otpError'],
  methods: {
    updateValue(event) {
      this.$emit('update:otp', event.target.value);
    },
  },
};
</script>
