<template>
  <KTCodePreview v-bind:title="'Khẩn cấp'" class="h-100">
    <template v-slot:preview>
      <b-row>
        <b-col cols="0" md="3" />
        <b-col cols="12" md="6" class="custom-card-body">
          <div class="shadow-sm p-8 bg-white rounded h-auto h-70">
            <transition name="slide-fade" mode="out-in">
              <RecheckPassword
                v-if="stepIndex === MULTI_STEPS_EMERGENCY.RECHECK_PASSWORD"
                v-bind:password.sync="password"
                :password-error="passwordError"
              />
              <OTPGeneration
                v-else-if="stepIndex === MULTI_STEPS_EMERGENCY.OTP_GENERATION"
                v-bind:otp.sync="otp"
                :otp-error="otpError"
              />
              <Success
                v-else-if="stepIndex === MULTI_STEPS_EMERGENCY.SUCCESS"
              />
              <Failed v-else-if="stepIndex === MULTI_STEPS_EMERGENCY.FAILED" />
            </transition>
            <div
              v-if="stepIndex !== MULTI_STEPS_EMERGENCY.SUCCESS"
              class="text-center"
            >
              <b-button
                variant="danger"
                @click="apiCallSwitcher"
                :disabled="isDisabledSubmitButton"
              >
                {{ submitButtonTextContent }}
                <b-spinner v-if="isFetching" small></b-spinner>
                <span class="sr-only">Loading...</span>
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col cols="0" md="3" />
      </b-row>
    </template>
  </KTCodePreview>
</template>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';

import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { SET_MENU } from '@/core/services/store/modules/menu.module';
import { SideBar } from '@/core/config/menu/sideBar';
import RecheckPassword from '@/view/pages/emergency/RecheckPassword.vue';
import OTPGeneration from '@/view/pages/emergency/OTPGeneration.vue';
import { cmdUrl } from '@/utils/apiUrl';
import ApiService from '@/core/services/api.service';
import Success from '@/view/pages/emergency/Success.vue';
import Failed from '@/view/pages/emergency/Failed.vue';

const MULTI_STEPS_EMERGENCY = Object.freeze({
  RECHECK_PASSWORD: 0,
  OTP_GENERATION: 1,
  SUCCESS: 2,
  IN_PROGRESS: 3,
  FAILED: 4,
});

export default {
  components: {
    KTCodePreview,
    RecheckPassword,
    OTPGeneration,
    Success,
    Failed,
  },
  data() {
    return {
      MULTI_STEPS_EMERGENCY,
      password: '',
      passwordError: {
        hasError: false,
        message: '',
      },
      otp: '',
      otpError: {
        hasError: false,
        message: '',
      },
      isFetching: false,
      isEmptyInput: true,
      stepIndex: 0,
      value: '',
      defaultError: {
        hasError: false,
        message: '',
      },
    };
  },
  watch: {
    password: {
      handler() {
        if (!this.password.length) {
          this.passwordError = this.assignDefaultErrorObject();
        }

        this.isEmptyInput =
          this.stepIndex === 0 && (!this.password || !this.password.length);
      },
      deep: true,
    },
    otp: {
      handler() {
        if (!this.otp.length) {
          this.otpError = Object.assign(
            {},
            {
              ...this.defaultError,
            },
          );
        }

        this.isEmptyInput =
          this.stepIndex === 1 && (!this.otp || !this.otp.length);
      },
      deep: true,
    },
  },
  methods: {
    nextStep() {
      if (this.stepIndex < Object.keys(this.MULTI_STEPS_EMERGENCY).length)
        this.stepIndex++;
    },
    postCheckPassword() {
      if (!this.password || !this.password.length) {
        this.passwordError = Object.assign(
          {},
          {
            hasError: true,
            message: 'Bạn chưa nhập mật khẩu',
          },
        );
        return;
      }
      this.isFetching = true;
      ApiService.post(cmdUrl.Emergency.checkPassword, {
        password: this.password,
      })
        .then(({ data }) => {
          if (data.data === true) {
            this.postGenerateOTP();
            this.nextStep();
          }
        })
        .catch((error) => {
          this.passwordError = Object.assign(
            {},
            {
              hasError: true,
              message: error.response.data.message,
            },
          );
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
    postGenerateOTP() {
      this.isFetching = true;
      ApiService.post(cmdUrl.Emergency.genOTP)
        .then(() => {
        })
        .catch((error) => {
          this.otpError = Object.assign(
            {},
            {
              hasError: true,
              message: error.response.data.message,
            },
          );
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
    postCheckOTP() {
      if (!this.otp || !this.otp.length) {
        this.otpError = Object.assign(
          {},
          {
            hasError: true,
            message: 'Bạn chưa nhập OTP',
          },
        );
        return;
      }

      this.isFetching = true;
      ApiService.post(cmdUrl.Emergency.checkOTP, {
        otpCode: this.otp,
      })
        .then(({ data }) => {
          if (data) {
            this.nextStep();
          } else {
            this.stepIndex = MULTI_STEPS_EMERGENCY.FAILED;
          }
        })
        .catch((error) => {
          this.otpError = Object.assign(
            {},
            {
              hasError: true,
              message: error.response.data.message,
            },
          );
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
    apiCallSwitcher() {
      switch (this.stepIndex) {
        case MULTI_STEPS_EMERGENCY.RECHECK_PASSWORD: {
          this.postCheckPassword();
          break;
        }
        case MULTI_STEPS_EMERGENCY.OTP_GENERATION: {
          this.postCheckOTP();
          break;
        }
        case MULTI_STEPS_EMERGENCY.FAILED: {
          this.stepIndex = MULTI_STEPS_EMERGENCY.RECHECK_PASSWORD;
          break;
        }
        default: {
          console.warn('Step index is out of bound or not specified');
        }
      }
    },
    assignDefaultErrorObject() {
      return Object.assign(
        {},
        {
          ...this.defaultError,
        },
      );
    },
  },

  computed: {
    isDisabledSubmitButton() {
      return !!this.isFetching || this.isEmptyInput;
    },
    submitButtonTextContent() {
      return this.stepIndex === this.MULTI_STEPS_EMERGENCY.FAILED
        ? 'Thử lại'
        : 'Xác nhận';
    },
  },
  mounted() {
    this.$store.dispatch(SET_MENU, SideBar.STORE);
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Khẩn cấp' }]);
  },
};
</script>
<style scoped>
p {
  margin-bottom: 0;
}

.slide-fade-enter-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
 {
  transform: translateX(10px);
  opacity: 0;
}
</style>
